<template>
  <PageContainer>
    <div class="layout">
      <div style="width: 100%;display: flex;align-items: center;">
        <el-form :inline="true" :model="searchModel" style="margin-top: 20px;padding-left: 20px" size="mini">
          <el-form-item label="工程名称:" prop="projName">
            <el-input
              v-model="searchModel.prjname"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="工程编号:" prop="projName">
            <el-input
              v-model="searchModel.prjnum"
            >
            </el-input>
          </el-form-item>
          <!--        <el-form-item label="施工单位:" prop="relaId">
          <el-select
            v-model="searchModel.relaId"
            placeholder="请选择施工单位"
            filterable
            clearable
            style="width: 200px"
          >
            <el-option
              v-for="item in relacsMap"
              :key="item.relaId"
              :label="item.firm"
              :value="item.relaId"
            >
            </el-option>
          </el-select>
        </el-form-item>-->
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="submitSearch" :loading="loading">{{searchText}}</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-close" v-if="loading" @click="loading = false">停止</el-button>
          </el-form-item>
        <!--        <el-form-item>
          <el-button
            type="primary"
            v-auth="'信息管理_工程管理_新增'"
            icon="el-icon-circle-plus-outline"
            @click="dialogEdit = true;addRow()"
          >新增工程</el-button
          >
        </el-form-item>-->
        </el-form>

      </div>

      <BaseTable
        id="projectManage"
        ref="xTable1"
        size="mini"
        border
        auto-resize
        resizable
        highlight-current-row
        highlight-hover-row
        export-config
        :align="allAlign"
        :seq-config="{startIndex: (tablePage.currentPage - 1) * tablePage.pageSize}"
        :data="tableData">
        <vxe-table-column type="seq" title="序号" width="60"/>
        <vxe-table-column field="certnum" title="施工许可证电子证照编号" show-overflow="title" show-header-overflow="title" min-width="155"/>
        <vxe-table-column field="projname" title="工程名称" show-overflow="title" min-width="200"
                          show-header-overflow="title"/>
        <vxe-table-column field="prjnum" title="工程编号" show-overflow="title" min-width="130"
                          show-header-overflow="title"/>
        <vxe-table-column field="prjtypenum" title="项目类别" show-overflow="title" min-width="100"
                          show-header-overflow="title"/>
        <vxe-table-column field="conprice" title="合同价格（万元）" show-overflow="title" min-width="125"
                          show-header-overflow="title"/>
        <vxe-table-column field="issudate" title="签发日期" show-overflow="title" min-width="100"
                          show-header-overflow="title"/>
        <vxe-table-column field="issuauth" title="发证机关" show-overflow="title" min-width="140"
                          show-header-overflow="title"/>
        <vxe-table-column field="issuauthcode" title="发证机关代码" show-overflow="title" min-width="140"
                          show-header-overflow="title"/>
        <!--        <vxe-table-column field="certstatus" title="施工许可证电子证照状态" show-overflow="title" min-width="100"
                          show-header-overflow="title"/>-->
        <vxe-table-column field="projloc" title="建设地址" show-overflow="title" min-width="160"
                          show-header-overflow="title"/>
        <vxe-table-column field="projowner" title="建设单位" show-overflow="title" min-width="160"
                          show-header-overflow="title"/>
        <vxe-table-column field="projownercode" title="建设单位代码" show-overflow="title" min-width="145"
                          show-header-overflow="title"/>
        <vxe-table-column field="projownercodetype" title="建设单位代码类型" show-overflow="title" min-width="120"/>
        <vxe-table-column field="pstartdate" title="计划开工日期" min-width="130" show-overflow="title"
                          show-header-overflow="title"/>
        <vxe-table-column field="totalharea" title="总面积（平方米）" min-width="130" show-overflow="title"
                          show-header-overflow="title"/>
        <vxe-table-column field="totalaharea" title="合计地上面积（平方米）" min-width="130" show-overflow="title"
                          show-header-overflow="title"/>
        <vxe-table-column field="totaluharea" title="合计地下面积（平方米）" min-width="130" show-overflow="title"
                          show-header-overflow="title"/>
        <!--        <vxe-table-column title="操作" min-width="100">
          <template v-slot="{ row }">
            <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editRow(row)" v-auth="'信息管理_工程管理_修改'" title="修改"/>
            <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="deleteRow(row)" v-auth="'信息管理_工程管理_删除'" title="删除"/>
          </template>
        </vxe-table-column>-->
      </BaseTable>
      <vxe-pager
        :loading="loading"
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :total="tablePage.total"
        :layouts="['Sizes','PrevPage','JumpNumber','NextPage','FullJump','Total']"
        @page-change="handlePageChange"
      />
      <el-dialog :title="dialogEditTitle"
                 :visible.sync="dialogEdit"
                 :v-if="dialogEdit"
                 :append-to-body="true"
                 width="750px"
                 v-dialogDrag
                 :close-on-click-modal="false"
                 :close-on-press-escape="false">

        <el-form :inline="true" ref="addForm" size="mini" label-position="right" label-width="120px" style="margin-top: 20px" >
          <el-form-item label="工程名称:" props="busLicPlate">
            <el-input
              v-model="projEditModel.busLicPlate"
              style="width: 180px"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="施工单位:" prop="relaId">
            <el-select
              v-model="projEditModel.relaId"
              placeholder="请选择施工单位"
              filterable
              clearable
              style="width: 200px"
            >
              <el-option
                v-for="item in relacsMap"
                :key="item.relaId"
                :label="item.firm"
                :value="item.relaId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="地址" props="address">
            <el-input
              placeholder="工程所在地址"
              v-model="projEditModel.address"
              style="width: 180px"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系人:">
            <el-select
              v-model="projEditModel.persId"
              placeholder="请选择联系人员"
              filterable
              clearable
              style="width: 200px"
              @change="selectPerson(projEditModel.persId)"
            >
              <el-option
                v-for="item in personMap"
                :key="item.persId"
                :label="item.persName"
                :value="item.persId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="联系电话:">
            <el-input
              v-model="projEditModel. phone"
              style="width: 200px"
              disabled
            >
            </el-input>
          </el-form-item>
          <el-form-item label="备注:">
            <el-input
              placeholder="需要备注的内容"
              v-model="projEditModel. lwhTank"
              style="width: 200px"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogBusEdit = false" size="mini">取消</el-button>
          <el-button type="primary" @click="onSubmitProjEdit()" size="mini">保存</el-button>
        </div>
      </el-dialog>
    </div>
  </PageContainer>
</template>

<script>
import { gainJzscProjPage, deleteProjecct, setProjecct } from '@/apis/infoManage/project'
// import { gianRelacsMore, gianPerscsMore } from '@/apis/common'
export default {
  name: 'project-manage',
  data () {
    return {
      personMap: new Map(),
      relacsMap: new Map(),
      loading: false,
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 30,
        align: 'right',
        pageSizes: [10, 20, 50, 100, 200, 500],
        layouts: ['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total'],
        perfect: true
      },
      searchText: '查询',
      allAlign: 'center',
      tableData: [],
      searchModel: {
        prjnum: '',
        prjname: ''
      },
      dialogEditTitle: '新增工程',
      dialogEdit: false,
      projEditModel: {
        address: '',
        projId: '',
        projName: '',
        relaId: '',
        persId: ''
      }

    }
  },
  methods: {
    // 分页变化
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage
      this.tablePage.pageSize = pageSize
      this.refreshData()
    },
    selectPerson (persId) {
      this.projEditModel.phone = this.personMap[persId].phone
    },
    submitSearch () {
      //  this.loading = true;
      this.tableData = []
      // this.currentBusIndex = 0;
      this.tablePage.currentPage = 1
      this.refreshData()
    },
    onSubmitProjEdit () {
      setProjecct(...this.projEditModel).then(res => {
        if (res.code === 200) {
          if (this.projEditModel.projId != '') { this.$message.success('修改成功！') } else { this.$message.success('添加成功！') }
          this.dialogEdit = false
          this.refreshData()
        } else { this.$message.error(res.msg) }
      })
    },
    editRow (row) {
      this.dialogEditTitle = '修改工程'
      const rowData = {}
      XEUtils.objectEach(this.projEditModel, (item, key) => {
        rowData[key] = row[key] ? row[key].toString() : ''
      })
      this.projEditModel = Object.assign({}, rowData)
      this.dialogEdit = true
    },
    deleteRow (row) {
      this.$confirm('确定删除该工程?', '删除工程', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        deleteProjecct({
          projId: row.projId
        }).then(res => {
          if (res.code === 200) {
            this.$message.success('删除成功！')
            this.refreshData()
          } else { this.$message.error(res.msg) }
        })
      })
    },
    refreshData () {
      this.loading = true
      gainJzscProjPage({
        ...this.searchModel,
        pageSize: this.tablePage.pageSize,
        pageNum: this.tablePage.currentPage
      }).then(res => {
        this.loading = false
        if (res.code == 200) {
          this.tableData = res.data.rows
          this.tablePage.total = res.data.totalSize
        } else {
          this.tableData = []
          this.tablePage.total = 0
        }
      }).catch(error => {
        console.log(error)
        //  this.logining = false;
      })
    },
    addRow () {
      this.dialogEditTitle = '新增工程'
      this.projEditModel = {
        address: '',
        projId: '',
        projName: '',
        relaId: '',
        persId: ''
      }
    }
  },
  mounted () {
    /* // 关联单位
    gianRelacsMore({
      relaType: 3// 施工单位
    }).then(res => {
      if (res.code == 200) {
        const relacsMap = new Map()
        res.data.forEach(rowData => {
          relacsMap.set(rowData.relaId, rowData)
        })
        this.relacsMap = relacsMap
      }
    })
    // 关联人员选择
    gianPerscsMore({
    }).then(res => {
      if (res.code == 200) {
        const personMap = new Map()
        res.data.forEach(rowData => {
          personMap.set(rowData.persId, rowData)
        })
        this.personMap = personMap
      }
    }).catch(res => {
      console.log(res)
    }) */
    this.searchModel = {
      prjnum: '',
      prjname: ''
    }
    this.refreshData()
  }
}
</script>

<style lang="scss" scoped>
    .layout {
        overflow: hidden;
        background-color: white;
    }
</style>
